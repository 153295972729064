import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Constants from '../services/constants';
import './tableau.scss';

function Tableau() {
  return (
    <Grid container className="tableau">
      <Grid item xs={12}>
        <Typography variant="h4" className="tableau-header">Tableau</Typography>
      </Grid>
      <Grid item xs={12} className="tableau-iframe-cell">
        <iframe 
          title="Tableau iframe"
          className="tableau-iframe" 
          src={`${Constants.tableauLinks[Constants.env]}/#/site/RAPTOR/explore?:display_count=n&:showVizHome=n&:origin=viz_share_link&:tabs=n&:toolbar=top`}
        />
      </Grid>
      <Grid item xs={12} className="tableau-cell">
        <Typography align="right" fontSize="14px" className="footer-text-contact">
          <a href={`${Constants.tableauLinks[Constants.env]}/#/site/RAPTOR/home`} target="_blank" rel="noreferrer noopener" className="tableau-link">Explore in Tableau</a>               
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Tableau;