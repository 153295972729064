import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import './platform.scss';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import DatasetService from '../services/datasetService';
import Utils from '../services/utils';
import BuTable from './BuTable/buTable';
import { getUserBus as actionGetUserBus } from '../auth/actions/authActions';

class platform extends React.Component {
  constructor(props, context) {
    super(props);

    this.state = {
      datasetSelected: '',
      dialogOpen: false,
      errorMessage: '',
      bus: [],
      busLoaded: false, 
      buSelected: '',
      buValue: '',
      adminError: '', 
      nameError: '',
      buError: '', 
      nameHelperText: '', 
      adminHelperText: '',
      buHelperText: '',
      creating: false
    };
  }

    // Closes dialog
    handleDialogClose = () => {
      this.setState({
        dialogOpen: false, errorMessage: '',
      });
    };

    // Function used by BU Table component to open the dialog and set specific values based on row
    openDialog = (dialog, status, buID, buName) => () => {
      const buSelected = {
        id: buID,
        name: buName
      };
      this.setState({
        dialogOpen: dialog, status, buSelected,  
      });
    };

    // Retrieves BU groups for user to be able to select from
    handleAddBuButton = () => {
      DatasetService.getBus().then((response) => {
        if (response.errorMessage) {
          this.setState({ dialogOpen: true, errorMessage: response.errorMessage });
        } else {
          this.setState({ dialogOpen: true, status: 'add', buGroups: response });
        }
      });
    };

    // Helps automatically refresh the page for the user when an action is taken
    refreshPage = () => {
      const { datasetSelected } = this.state;
      this.setState({ busLoaded: false, dialogOpen: false });
      DatasetService.getDatasetBus(datasetSelected).then((response) => {
        if (response.errorMessage) {
          this.setState({ dialogOpen: true, errorMessage: response.errorMessage });
        } else {
          this.setState({
            bus: response, 
            busLoaded: true, 
            status: '', 
            buValue: '', 
            nameError: '', 
            adminError: '', 
            nameHelperText: '', 
            adminHelperText: '' 
          });
        }
      });
    }
    
    // Close dialog and reset BU and status values
    handleCancel = () => {
      this.setState({
        dialogOpen: false, 
        buValue: '', 
        status: '', 
        adminError: '', 
        nameError: '', 
        buError: '', 
        nameHelperText: '', 
        adminHelperText: '', 
        buHelperText: '' 
      });
    };

    // Delete BU from dataset
    handleDelete = () => {
      const { buSelected, datasetSelected } = this.state;
      DatasetService.deleteBuFromDataset(datasetSelected, buSelected.id).then((response) => {
        if (response.errorMessage) {
          this.setState({ dialogOpen: true, errorMessage: response.errorMessage });
        } else {
          this.refreshPage();
        }
      });
    }

    // Add BU to dataset then close modal once you get a response
    handleAdd = () => {
      const { buValue, datasetSelected } = this.state;
      const { businessUnitGroupID } = buValue;
      if (businessUnitGroupID) {
        DatasetService.saveBuToDataset(datasetSelected, businessUnitGroupID).then((response) => {
          if (response.errorMessage) {
            this.setState({ dialogOpen: true, errorMessage: response.errorMessage });
          } else {
            this.refreshPage();
          }
        });
      } else {
        this.setState({ buError: true, buHelperText: 'Please select a business unit group' });
      }
    }

    // Handles creation of business unit.
    // Creates new BU, adds admin to new BU, and saves BU to dataset.
    handleCreate = () => {
      const { buValue, datasetSelected, creating } = this.state;
      const { getUserBus } = this.props;
      // Checks that both name and admin SSO fields are populated/valid
      if (buValue.businessUnitName && buValue.adminSso && buValue.adminSso.length === 9) {
        const payload = {
          businessUnitName: buValue.businessUnitName,
          description: buValue.description
        };
        this.setState({ creating: true });
        DatasetService.postBu(payload).then((response) => {
          if (response.errorMessage) {
            this.setState({ 
              dialogOpen: true, 
              errorMessage: response.errorMessage, 
              creating: false 
            });
          } else {
            const { businessUnitGroupID } = response;
            getUserBus();
            const ssoID = buValue.adminSso;
            const body = {
              role: 'admin'
            };
            DatasetService.saveUserToBu(businessUnitGroupID, ssoID, body).then((userResponse) => {
              if (userResponse.errorMessage) {
                this.setState({ 
                  dialogOpen: true, 
                  errorMessage: userResponse.errorMessage, 
                  creating: false 
                });
              } else {
                DatasetService.saveBuToDataset(datasetSelected, businessUnitGroupID)
                  .then((datasetResponse) => {
                    if (datasetResponse.errorMessage) {
                      this.setState({
                        dialogOpen: true, 
                        errorMessage: datasetResponse.errorMessage, 
                        creating: false 
                      });
                    } else {
                      this.setState({ creating: false });
                      this.refreshPage();
                    }
                  });
              }
            });
          }
        });
      } else if (buValue.businessUnitName === undefined || buValue.businessUnitName === '') {
        // If the name is empty, show error and helper text to enter name
        this.setState({ nameError: true, nameHelperText: 'Please enter a name' });
      }
      // If SSO is empty or not 9 characters, show error and helper text to enter valid SSO
      if (buValue.adminSso === undefined || buValue.adminSso.length !== 9 || buValue.adminSso === '') {
        this.setState({ adminError: true, adminHelperText: 'Please enter a valid SSO ID' });
      }
    }
  
    // Sets new business unit name entered and opens create dialog
    handleAutocompleteChange = (event, newValue) => {
      if (newValue) {
        this.setState({ buError: false, buHelperText: '' });
        if (typeof newValue === 'string') {
          this.setState({ buValue: newValue });
        } else if (newValue && newValue.inputValue) {
          const { buValue } = this.state;
          const newBuValue = { ...buValue };
          newBuValue.businessUnitName = newValue.inputValue;
          newBuValue.description = '';
          this.setState({ buValue: newBuValue, status: 'create' });
        } else {
          this.setState({ buValue: newValue });
        }
      }   
    }

    // Filters options in business unit name text box based on input
    filterOptions = (options, params) => {
      const filter = createFilterOptions();
      const filtered = filter(options, params);

      // Suggest the creation of a new value
      if (params.inputValue !== '') {
        filtered.push({
          inputValue: params.inputValue,
          businessUnitName: `Create "${params.inputValue}"`,
        });
      }

      return filtered;
    }

    // Creates options shown based on text entered. 
    // Also gives option to add value if it does not match.
    createOptionLabel = (option) => {
      // Value selected with enter, right from the input
      if (typeof option === 'string') {
        return option;
      }
      // Add "xxx" option created dynamically
      if (option.inputValue) {
        return option.inputValue;
      }
      // Regular option
      return option.businessUnitName;
    }

    // Handles change of business unit object when creating a new business unit 
    //  (name, admin SSO, and description)
    buObjChange = (prop) => (event) => {
      const { buValue } = this.state;
      const newBuValue = { ...buValue };
      newBuValue[prop] = event.target.value;

      if (prop === 'adminSso') {
        const validation = !Utils.isNumber(event.target.value);
        let adminHelperText = '';
        if (validation) {
          adminHelperText = 'Please enter a numeric value';
        }
        this.setState({ adminError: validation, adminHelperText, buValue: newBuValue });
      } else if (prop === 'businessUnitName') {
        // Resets the error and helper text when a name is inputted if there was an error
        this.setState({ buValue: newBuValue, nameError: false, nameHelperText: '' });
      } else {
        this.setState({ buValue: newBuValue });
      }
    }

   // Update state with dataset selected if it changed
   handleDatasetChange = (event, newValue) => {
     if (newValue) {
       const { datasetSelected } = this.state;
       const newDataset = newValue;
      
       if (newDataset !== datasetSelected) {
         this.setState({ datasetSelected: newDataset });
         DatasetService.getDatasetBus(newDataset).then((response) => {
           if (response.errorMessage) {
             this.setState({ dialogOpen: true, errorMessage: response.errorMessage });
           } else {
             this.setState({ bus: response, busLoaded: true });
           }
         });
       }
     }
   };
  
   // Create an autocomplete select box for datasets
  generateAutocomplete = () => {
    const { datasetSelected } = this.state;
    const { dataset } = this.props;
    const { datasetObjs } = dataset;

    const options = datasetObjs.map((a) => a.datasetName);
    const optionsText = 'No Datasets Found';

    const autocomplete = (
      <Autocomplete
        id="autocomplete-bu"  
        value={datasetSelected}
        options={options}
        onChange={this.handleDatasetChange} 
        noOptionsText={optionsText}
        selectOnFocus
        closeIcon=""
        getOptionLabel={(option) => (option || '')}
        getOptionSelected={(option, value) => {
          if (value === '') {
            return true;
          } if (value === option) {
            return true;
          }
          return false;
        }}
        classes={{ inputRoot: 'menu-selected', root: 'menu-selected' }}
        style={{ minWidth: 280 }}
        renderInput={(params) => (
          <TextField 
            InputProps={{
              ...params.InputProps,
              inputProps: {
                ...params.inputProps
              }
            }} 
            label="Dataset"
            InputLabelProps={{ classes: { root: 'menu-selected' } }}
          />
        )}
      />
    );

    return autocomplete;
  }

  // Generates dialog based on status
  generateDialog() {
    let dialogContent;
    let dialogActions;
    let dialogTitle;
    const {
      status, 
      errorMessage, 
      dialogOpen, 
      buValue, 
      buGroups, 
      adminError, 
      nameError, 
      nameHelperText, 
      adminHelperText, 
      buSelected, 
      buError, 
      buHelperText,
      creating
    } = this.state;

    if (errorMessage) {
      dialogTitle = <DialogTitle id="alert-dialog-title" className="platform-dialog-title">An error occurred</DialogTitle>;
      dialogContent = (
        <DialogContent className="platform-dialog">
          <div className="error-icon-div">
            <ErrorOutlineOutlinedIcon className="error-icon" />
          </div>
          <DialogContentText id="message">
            {errorMessage}
          </DialogContentText>
        </DialogContent>
      );
      dialogActions = (
        <DialogActions>
          <Button onClick={this.handleDialogClose} className="platform-dialog-button">
            OK
          </Button>
        </DialogActions>
      );
    } else if (status === 'add') {
      let buInputClass = 'bu-group-text';
      if (buError) {
        buInputClass = 'bu-group-text-error';
      }
      dialogTitle = <DialogTitle id="alert-dialog-title" className="platform-dialog-title">Add Business Unit Group to Dataset</DialogTitle>;
      dialogContent = (
        <DialogContent classes={{ root: 'platform-dialog-content' }}>
          <Autocomplete
            value={buValue}
            onChange={this.handleAutocompleteChange}
            filterOptions={this.filterOptions}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="bu-group-select"
            options={buGroups}
            getOptionLabel={this.createOptionLabel}
            renderOption={(option) => option.businessUnitName}
            // inputRoot overrides the input label settings for Autocomplete
            //  (doesn't work at textfield level)
            classes={{ inputRoot: buInputClass, root: buInputClass }}
            freeSolo
            renderInput={(params) => (
              <TextField
                error={buError}
                InputProps={{
                  ...params.InputProps,
                  inputProps: {
                    ...params.inputProps
                  }
                }}
                label="Business Unit Group"
                helperText={buHelperText}
                InputLabelProps={{ classes: { root: buInputClass } }}
              />
            )}
          />
        </DialogContent>
      );

      dialogActions = (
        <DialogActions classes={{ root: 'platform-dialog' }}>
          <Button onClick={this.handleCancel} className="platform-dialog-button">
            Cancel
          </Button>
          <Button onClick={this.handleAdd} className="platform-dialog-button">
            Add
          </Button>
        </DialogActions>
      );
    } else if (status === 'create') {
    // Helps determine which class to use (needed for error formatting) for SSO ID textfield
      let nameInputClass = 'text-box';
      if (nameError) {
        nameInputClass = 'name-text-box-error';
      }

      let adminInputClass = 'text-box';
      if (adminError) {
        adminInputClass = 'admin-text-box-error';
      }

      dialogTitle = <DialogTitle id="alert-dialog-title" className="platform-dialog-title-create">Create Business Unit Group</DialogTitle>;
      if (!creating) {
        dialogContent = (
          <DialogContent>
            <div className="bu-name">
              <TextField
                error={nameError}
                key="bu-name"
                required
                onChange={this.buObjChange('businessUnitName')}
                value={buValue.businessUnitName}
                label="Name"
                InputLabelProps={{ className: nameInputClass }}
                InputProps={{
                  className: nameInputClass
                }}
                helperText={nameHelperText}
              />
            </div>
            <div className="bu-admin">
              <TextField
                error={adminError}
                key="bu-admin"
                required
                onChange={this.buObjChange('adminSso')}
                value={buValue.adminSso}
                label="Admin SSO"
                InputLabelProps={{ className: adminInputClass }}
                InputProps={{
                  className: adminInputClass
                }}
                helperText={adminHelperText}
              />
            </div>
            <div className="bu-desc">
              <TextField
                key="bu-desc"
                onChange={this.buObjChange('description')}
                value={buValue.description}
                label="Description"
                className="bu-desc"
                InputProps={{
                  className: 'text-box'
                }}
              />
            </div>
  
          </DialogContent>
        );

        dialogActions = (
          <DialogActions classes={{ root: 'platform-dialog' }}>
            <Button onClick={this.handleCancel} className="platform-dialog-button">
              Cancel
            </Button>
            <Button onClick={this.handleCreate} className="platform-dialog-button">
              Create
            </Button>
          </DialogActions>
        );
      } else {
        dialogContent = (
          <div className="cp-create">
            <CircularProgress className="circular-progress" />
          </div> 
        );
      }
    } else if (status === 'delete') {
      dialogTitle = (
        <DialogTitle id="alert-dialog-title">
          {`Delete "${buSelected.name}" Business Unit Group From Dataset?`}
        </DialogTitle>
      );
      dialogContent = '';
      dialogActions = (
        <DialogActions classes={{ root: 'platform-dialog' }}>
          <Button onClick={this.handleCancel} className="platform-dialog-button">
            Cancel
          </Button>
          <Button onClick={this.handleDelete} className="platform-dialog-delete-button">
            Delete
          </Button>
        </DialogActions>
      );
    }

    const dialog = (
      <Dialog
        key="dialog"
        open={dialogOpen}
        onClose={this.handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ root: 'platform-dialog' }}
      >
        {dialogTitle}
        {dialogContent}
        {dialogActions}
      </Dialog>
    );

    return dialog;
  }

  // Generates BU section with table and add group button
  generateBuSection(bus, busLoaded) {
    if (busLoaded && bus !== undefined) {
      return (
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h5" className="bu-groups-header">Business Unit Groups</Typography>
          </Grid>
          <Grid item xs={10} />
          <BuTable
            bus={bus}
            openDialog={this.openDialog}
            status="delete"
          />
          <Grid key="upload-button" item xs={2} className="upload-button-div">
            <Button variant="outlined" className="upload-button" onClick={this.handleAddBuButton}>
              Add Group
            </Button>
          </Grid>
        </Grid>
      );
    }
    // Returns spinny if not loaded
    return (
      <Grid container>
        <Grid item xs={12}>
          <div className="cp-div">
            <CircularProgress className="circular-progress" />
          </div>
        </Grid>
      </Grid>
    );
  }

  render() {
    const { auth, dataset } = this.props;
    const {
      datasetSelected, dialogOpen, bus, busLoaded
    } = this.state;
    const { didLoad } = dataset;
    const { isAuthenticated } = auth;

    if (didLoad && isAuthenticated) {
      return (
        <Grid container className="platform">
          <Grid item xs={12} key="admin-header">
            <Typography variant="h4" className="admin-header">Platform Admin</Typography>
          </Grid>
          <Grid item xs={6} className="select-boxes">
            <FormControl className="form-control">
              {this.generateAutocomplete()}
            </FormControl>
          </Grid>
          {datasetSelected !== '' ? this.generateBuSection(bus, busLoaded) : null}
          {dialogOpen ? this.generateDialog() : null}
        </Grid>
      );
    }
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h4" className="admin-header">Platform Admin</Typography>
          <div className="cp-div">
            <CircularProgress className="circular-progress" />
          </div>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  dataset: state.dataset,
  auth: state.auth
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getUserBus: actionGetUserBus
  }, dispatch);
}

platform.propTypes = {
  dataset: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  getUserBus: PropTypes.func.isRequired
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(platform));
