import React from 'react';
import { 
  AppBar, Toolbar, Hidden, Popper, Tab, Tabs, Tooltip, Paper, MenuList, MenuItem 
} from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import MaterialLink from '@material-ui/core/Link';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import AccountCircle from '@material-ui/icons/AccountCircle';
import PropTypes from 'prop-types';
import Logo from '../assets/NBCU Purple Circle.png';

import Constants from '../services/constants';
import './header.scss';
import Utils from '../services/utils';

class Header extends React.Component {
  constructor(props, context) {
    super(props);
    const { location } = this.props;

    const pages = [
      {
        path: '/userAdmin', value: 'admin', tab: 'platformAdmin', label: 'User Group' 
      },
      {
        path: '/platformAdmin', value: 'admin', tab: 'platformAdmin', label: 'Platform' 
      },
      {
        path: '/userAdmin', value: 'admin', tab: 'userAdmin', label: 'User Group' 
      },
      {
        path: '/browse', value: 'explore', tab: 'explore', label: 'Browse' 
      },
      {
        path: '/export', value: 'explore', tab: 'explore', label: 'Export' 
      },
      {
        path: '/upload', value: 'explore', tab: 'explore', label: 'Upload' 
      },
      {
        path: '/tableau', value: 'explore', tab: 'explore', label: 'Tableau' 
      },
      {
        externalPath: Constants.links.snowflake, value: 'explore', tab: 'explore', label: 'Snowflake' 
      },
      {
        path: '/retool', value: 'explore', tab: 'explore', label: 'Retool' 
      },
      {
        externalPath: Constants.links.davd, value: 'connections', tab: 'connections', label: 'DAVD' 
      },
      {
        externalPath: Constants.links.radar, value: 'connections', tab: 'connections', label: 'Radar' 
      },
      // {
      //   externalPath: Constants.links.forecastManager, value: 'connections', tab: 'connections', label: 'Forecast Manager' 
      // },
      {
        externalPath: Constants.links.confluence, value: 'reference', tab: 'reference', label: 'Platform Info' 
      },
    ];

    const { pathname } = location;
    let value = '';
   
    // Sets the value based on the path name on initial load
    if (pathname) {
      pages.forEach((page) => {
        if (page.path === pathname) {
          value = `/${page.value}`;
        }
      });
    
      this.state = {
        value,
        open: false,
        anchorEl: null,
        role: '',
        pages
      };
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { auth } = this.props;
    const { role } = this.state;
    const { userInfo, isAuthenticated } = auth;
    const { isAdminOf, platformAdmin } = userInfo;

    if (isAuthenticated && role === '' && prevState.role === '') {
      const newRole = Utils.getRole(platformAdmin, isAdminOf);
      this.setState({ role: newRole });
    }
  } 
  
  handleMenuOpen = (tab) => (event) => {
    const { currentTarget } = event;
    this.setState({
      open: true,
      anchorEl: currentTarget,
      tab
    });
  };

  handleMenuClose = (item) => () => {
    if (item) {
      let { value } = item;
      if (value) {
        value = `/${value}`;
        this.setState({ open: false, anchorEl: null, value });
      } else {
        this.setState({ open: false, anchorEl: null });
      }
    }
  };

  generateMenuItems = (tabPages) => {
    const menuItems = tabPages.map((item) => {
      if (item.externalPath) {
        return (
          <MaterialLink href={item.externalPath} rel="noreferrer noopener" target="_blank" className="link">
            <MenuItem 
              key={item.externalPath} 
              onClick={this.handleMenuClose(item)}
              className="menu-item"
              onMouseEnter={(e) => e.target.style.backgroundColor = '#495054'}
              onMouseLeave={(e) => e.target.style.backgroundColor = 'black'}
            >
              {item.label}
            </MenuItem>
          </MaterialLink>   
        );
      } 
      return (
        <MenuItem 
          component={Link}
          key={item.path} 
          onClick={this.handleMenuClose(item)}
          to={item.path}
          className="menu-item"
          onMouseEnter={(e) => e.target.style.backgroundColor = '#495054'}
          onMouseLeave={(e) => e.target.style.backgroundColor = 'black'}
        >
          {item.label}
        </MenuItem>
      );
    });
    return menuItems;
  }
  
  generateMenu = (tab) => {
    const { anchorEl, open, pages } = this.state;
    if (tab) {
      const tabPages = pages.filter((page) => page.tab === tab);

      return (
        <Popper open={open} anchorEl={anchorEl} onMouseLeave={this.handleMenuClose({ value: tabPages.value })} id="menu-list-grow">
          <Paper>
            <MenuList className="menu">
              {this.generateMenuItems(tabPages)}
            </MenuList>
          </Paper>
        </Popper>
      );
    }
  }
  
  render() {
    const { auth } = this.props;
    let username = <div className="user-name-section" />;
    let navigationLinksSection = <div className="navigation-links-section" />;

    const { userInfo, isAuthenticated } = auth;
    const { value, role, tab } = this.state;

    if (isAuthenticated) {
      const { FirstName, LastName } = userInfo;
      const userName = `${FirstName} ${LastName}`;

      username = (
        <div>
          <div className="user-name-section">
            <Tooltip disableFocusListener disableTouchListener title={userName}>
              <AccountCircle className="user-name-section" />
            </Tooltip>
          </div>
        </div>
      );

      let adminTab;

      if (role === 'platformAdmin' || role === 'userAdmin') {
        adminTab = (
          <Tab 
            value="/admin"
            label={<span className="tab">Admin</span>} 
            disableTouchRipple 
            onMouseEnter={this.handleMenuOpen(role)}
          />
        );
      } 
      
      navigationLinksSection = (
        <Tabs
          value={value}
          classes={{
            root: 'tab',
            flexContainer: 'tab',
            indicator: 'tab-indicator'
          }}
          style={{ flex: 1 }}
        >
          <Tab  
            value="/explore" 
            label={<span className="tab">Explore</span>} 
            disableTouchRipple
            className="tab"
            onMouseEnter={this.handleMenuOpen('explore')}
          />
          <Tab value="/connections" label={<span className="tab">Connections</span>} to="/export" disableTouchRipple onMouseEnter={this.handleMenuOpen('connections')} />
          <Tab value="/reference" label={<span className="tab">Reference</span>} to="/upload" disableTouchRipple onMouseEnter={this.handleMenuOpen('reference')} />
          { adminTab }
        </Tabs>
      );
    }
    return (
      <div>
        <AppBar>
          <Toolbar className="section-header">
            <div className="logo">
              <Link className="logo" to="/home">
                <img src={Logo} alt="NBCU" width="50" />
              </Link>
            </div>
            { navigationLinksSection }
            { this.generateMenu(tab) }
            <Hidden mdDown>
              { username }
            </Hidden>
          </Toolbar>
        </AppBar>
      </div>
    );
  }    
}

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

Header.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
