import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Tableau from '../assets/tableau.jpg';
import Snowflake from '../assets/snowflake.png';
import Retool from '../assets/retool.png';
import Collibra from '../assets/collibra.png';
import Confluence from '../assets/confluence.jpeg';
import Davd from '../assets/davd.png';
import Radar from '../assets/radar_logo.png';

import Constants from '../services/constants';
import './home.scss';

function Home() {
  return (
    <Grid container className="home">
      <Grid item xs={12} className="home-headers">
        <Typography variant="h5">Explore</Typography>
      </Grid>
      <Grid item xs={2} className="home-tile">
        <div className="tile">
          <Link to="/tableau">
            <img src={Tableau} alt="Tableau" className="tile-image" />
          </Link>
          <Typography variant="h5" className="browseData-header">Tableau</Typography>
        </div>
      </Grid>
      <Grid item xs={2} className="home-tile">
        <div className="tile">
          <Link to="/retool">
            <img src={Retool} alt="Retool" className="smaller-tile-image" />
          </Link>
          <Typography variant="h5" className="smaller-tile-text">Retool</Typography>
        </div>
      </Grid>
      <Grid item xs={2} className="home-tile">
        <div className="tile">
          <a href={Constants.links.snowflake} rel="noreferrer noopener" target="_blank" className="home-link">
            <img src={Snowflake} alt="Snowflake" className="tile-image" />
            <Typography variant="h5" className="browseData-header">Snowflake</Typography>
          </a>
        </div>
      </Grid>
      <Grid item xs={2} className="home-tile">
        <div className="tile">
          <Link to="/browse">
            <SearchIcon className="icons" />
          </Link>
          <Typography variant="h5" className="browseData-header">Browse</Typography>
        </div>
      </Grid>
      <Grid item xs={2} className="home-tile">
        <div className="tile">
          <Link to="/export">
            <CloudDownloadIcon className="icons" />
          </Link>
          <Typography variant="h5" className="browseData-header">Export</Typography>
        </div>
      </Grid>
      <Grid item xs={2} className="home-tile">
        <div className="tile">
          <Link to="/upload">
            <CloudUploadIcon className="icons" />
          </Link>
          <Typography variant="h5" className="browseData-header">Upload</Typography>
        </div>
      </Grid>
      <Grid item xs={12} className="home-headers">
        <Typography variant="h5">Connections/Reference</Typography>
      </Grid>
      <Grid item xs={2} className="home-tile">
        <div className="tile">
          <a href={Constants.links.radar} rel="noreferrer noopener" target="_blank" className="home-link">
            <img src={Radar} alt="Radar" className="smaller-tile-image" />
            <Typography variant="h5" className="home-link">Radar</Typography>
          </a>
        </div>
      </Grid>
      {/* <Grid item xs={2} className="home-tile">
        <div className="tile">
          <a href={Constants.links.forecastManager} rel="noreferrer noopener" target="_blank">
            <img src={Retool} alt="Retool" className="smaller-tile-image" />
          </a>
          <Typography variant="h5" className="smaller-tile-text">Forecast Manager</Typography>
        </div>
      </Grid> */}
      <Grid item xs={2} className="home-tile">
        <div className="tile">
          <a href={Constants.links.davd} rel="noreferrer noopener" target="_blank">
            <img src={Davd} alt="Davd" className="davd-tile" />
          </a>
          <Typography variant="h5" className="davd-tile-text">DAVD</Typography>
        </div>
      </Grid>
      <Grid item xs={2} className="home-tile">
        <div className="tile">
          <a href={Constants.links.confluence} rel="noreferrer noopener" target="_blank" className="home-link">
            <img src={Confluence} alt="Confluence" className="smaller-tile-image" />
            <Typography variant="h5" className="info-text">Platform Info</Typography>
          </a>
        </div>
      </Grid>
    </Grid>
  );
}

export default Home;
