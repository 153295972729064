class Constants {
    static env = process.env.REACT_APP_ENV;

    static awsRegion = process.env.REACT_APP_REGION;

    static apiGatewayId = process.env.REACT_APP_API_GATEWAY_ID;

    static apiGatewayUrl = `https://${Constants.apiGatewayId}.execute-api.${Constants.awsRegion}.amazonaws.com/${Constants.env}`;

    static fileExtSupported = ['xls', 'xlsx', 'csv', 'tsv'];

    static fileExt = this.fileExtSupported.map(i => '.' + i);

    static conversionFileExt = ['xls', 'xlsx'];

    static urlStatus = 'url_generated';
    
    static cancelStatus = 'canceled';

    static uploadFailedStatus = 'upload_failed';
    
    static uploadedStatus = 'uploaded';

    static uploadingStatus = 'uploading';

    static canceledStatus = 'canceled';

    static beginProcessStatus = 'begin_processing';

    static convertedStatus = 'converted';

    static validatedStatus = 'validated';

    static validationFailedStatus = 'validation_failed';

    static conversionFailedStatus = 'conversion_failed';

    static processingStatus = 'processing';

    static processedStatus = 'processed';

    static loadFailedStatus = 'load_failed';

    static successStatus = 'success';

    static processedStatuses = [
      this.validationFailedStatus, 
      this.validatedStatus, 
      this.cancelStatus,
      this.successStatus,
      this.conversionFailedStatus
    ];

    static conversionStatuses = [
      this.cancelStatus,
      this.successStatus,
      this.conversionFailedStatus,
      this.convertedStatus
    ]

    static noProcessingStatuses = [
      this.cancelStatus,
      this.successStatus,
      this.beginProcessStatus
    ]

    static scope = 'openid%20ResearchTransformAdminMFA%20profile%20ExtMFAEnabledGrp&access_token_manager_id=GroupReferenceTokensGrp';

    static links = {
      confluence: 'https://wiki.inbcu.com/pages/viewpage.action?pageId=317887580',
      davd: 'https://davd.inbcu.com/#/interactive-dashboards',
      radar: 'https://radar.inbcu.com/',
      snowflake: 'https://nbcu.us-east-1.snowflakecomputing.com/',
      forecastManager: 'https://forecastmanager.inbcu.com'
    }

    static tableauLinks = {
      dev: 'https://sratableauint.inbcu.com',
      uat: 'https://sratableauint.inbcu.com',
      prod: 'https://sharedtableauprod2.inbcu.com'
    }

    static retoolLinks = {
      dev: 'https://retool-dev.inbcu.com/',
      uat: 'https://retool-uat.inbcu.com/',
      prod: 'https://retool.inbcu.com/'
    }
}

export default Constants;