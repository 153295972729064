import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import queryString from 'query-string';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import CancelIcon from '@material-ui/icons/Cancel';
import ErrorIcon from '@material-ui/icons/Error';
import SyncIcon from '@material-ui/icons/Sync';
import DescriptionIcon from '@material-ui/icons/Description';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import CloudIcon from '@material-ui/icons/Cloud';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { v4 as uuidv4 } from 'uuid';
import Box from '@material-ui/core/Box';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircle from '@material-ui/icons/CheckCircle';

import './uploadData.scss';
import HistoryTable from './HistoryTable/historyTable';
import DatasetService from '../services/datasetService';
import Constants from '../services/constants';
import Utils from '../services/utils';

class uploadData extends React.Component {
  constructor(props, context) {
    super(props);

    const { location } = this.props;
    const { search } = location;
    const params = queryString.parse(search);
    const datasetSelected = params.dataset ? params.dataset : '';
    const tableSelected = params.table ? params.table : '';

    this.state = {
      datasetSelected,
      tableSelected,
      dialogOpen: false,
      isUploading: false,
      errorMessage: '',
      historyObjs: null,
      historyLoaded: false,
      files: [],
      validation: '',
      info: false
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Pulls the history if there is a table selected but no history objects
    const { 
      tableSelected, 
      historyObjs, 
      datasetSelected, 
      historyLoaded, 
      errorMessage,
      validation 
    } = this.state;
    if (
      tableSelected !== '' && historyObjs === null && prevState.tableSelected !== '' && historyLoaded === false 
      && prevState.historyObjs === null && errorMessage === ''
    ) {
      DatasetService.getUploadHistory(datasetSelected, tableSelected)
        .then((response) => {
          if (response.errorMessage === '' || response.errorMessage === undefined) {
            const newHistoryObjs = response.sort((a, b) => b.timestamp - a.timestamp);
            this.setState({ historyObjs: newHistoryObjs, historyLoaded: true });
          } else {
            this.setState({ 
              dialogOpen: true, 
              errorMessage: response.errorMessage, 
              isUploading: false 
            });
          }     
        }).catch((error) => {
          this.setState({ dialogOpen: true, errorMessage: error.message, isUploading: false });
        });
    }

    if ((tableSelected !== '' && validation === '') || (tableSelected !== prevState.tableSelected)) {
      this.getValidation(tableSelected);
    }
  }

  getStatusIcon(status, fileId) {
    const { validation } = this.state;

    if (status === Constants.uploadFailedStatus) {
      return (
        <Tooltip disableFocusListener disableTouchListener title="Upload Error">
          <ErrorIcon className="error-icon" />
        </Tooltip>  
      );
    } if (status === Constants.canceledStatus) {
      return (
        <Tooltip disableFocusListener disableTouchListener title="Canceled">
          <ErrorIcon className="warning-icon" />
        </Tooltip>  
      );
    } if (status === Constants.uploadingStatus) {
      return (
        <Button disableFocusRipple onClick={this.handleUploadCancel(fileId)} classes={{ root: 'cancel-button', label: '' }}>
          <Tooltip disableFocusListener disableTouchListener title="Cancel">
            <CancelIcon className="cancel-icon" />
          </Tooltip>
        </Button>
      );
    } if (status === Constants.uploadedStatus || status === Constants.urlStatus
      || (validation && status === Constants.beginProcessStatus)) {
      return (
        <Tooltip disableFocusListener disableTouchListener title="Verifying">
          <div className="cloud-icon-div">     
            <CloudIcon className="cloud-icon" />
            <CircularProgress size={45} className="cloud-circular-progress" />  
          </div>   
        </Tooltip>         
      );
    } if (status === Constants.validationFailedStatus) {
      return (
        <Tooltip disableFocusListener disableTouchListener title="Validation Error">
          <ErrorIcon className="error-icon" />
        </Tooltip>  
      );
    } if (status === Constants.conversionFailedStatus) {
      return (
        <Tooltip disableFocusListener disableTouchListener title="Conversion Error">
          <ErrorIcon className="error-icon" />
        </Tooltip>  
      );
    } if (status === Constants.validatedStatus 
      || status === Constants.convertedStatus 
      || (!validation && status === Constants.beginProcessStatus)) {
      return (
        <Tooltip disableFocusListener disableTouchListener title="Verified">
          <CloudDoneIcon className="cloud-done-icon" />
        </Tooltip> 
      );
    } 
    return null;
  }

  handleRefresh = () => {
    const { datasetSelected, tableSelected } = this.state;
    this.setState({ historyLoaded: false });
    DatasetService.getUploadHistory(datasetSelected, tableSelected)
      .then((response) => {
        if (response.errorMessage === '' || response.errorMessage === undefined) {
          const newHistoryObjs = response.sort((a, b) => b.timestamp - a.timestamp);
          this.setState({ historyObjs: newHistoryObjs, historyLoaded: true });
        } else {
          this.setState({ 
            dialogOpen: true, 
            errorMessage: response.errorMessage, 
            isUploading: false 
          });
        }
      }).catch((error) => {
        this.setState({ dialogOpen: true, errorMessage: error.message, isUploading: false });
      });
  };

  // Cancels the upload
  handleUploadCancel = (fileId) => () => {
    DatasetService.cancelUpload(fileId);
  };

  // Closes the dialog and resets the state
  handleDialogClose = () => {
    document.getElementById('fileInput').value = '';
    this.handleRefresh();
    this.setState({
      dialogOpen: false, 
      errorMessage: '', 
      isUploading: false, 
      files: [],
      conversion: false,
      validation: '',
      info: false
    });
  };

  // Calculates the progress percentage for the upload
  calculateProgress = (totalBytes, loadedBytes) => {
    let progress = 0;
    if (totalBytes !== 0) {
      progress = (loadedBytes / totalBytes) * 100;
    }
    return progress;
  }
  
  uploadFile = (presignFileObj, presignedURL, fileId, i) => {
    const { files } = this.state;
    const newFiles = files.slice();

    return (
      DatasetService.uploadFile(presignFileObj, presignedURL, fileId, (progressEvent) => {     
        newFiles[i].loadedBytes = progressEvent.loaded;
        newFiles[i].totalBytes = progressEvent.total;
        newFiles[i].status = Constants.uploadingStatus;
        this.setState({ files: newFiles });                 
      }).then((uploadResponse) => {
        newFiles[i].status = Constants.uploadedStatus;
        // If upload is cancelled
        if (Utils.isNotNullorEmpty(uploadResponse.cancelMessage)) {
          newFiles[i].status = Constants.canceledStatus;
          newFiles[i].loadedBytes = 0;
          newFiles[i].totalBytes = 0;     
        } else if (Utils.isNotNullorEmpty(uploadResponse.errorMessage)) {
          newFiles[i].status = Constants.uploadFailedStatus;
        } 
        this.setState({ files: newFiles });
        return uploadResponse;
      }).catch((error) => {
        this.setState({
          dialogOpen: true, errorMessage: error.message, isUploading: false, files: []
        });
      })
    );
  }

  // Get file statuses for specified upload ID and keep pulling until proceessed status is received
  waitForVerifiedStatus = (uploadId) => {
    const { files } = this.state;

    DatasetService.getUploadStatus(uploadId)
      .then((response) => {
        if (response.errorMessage != null) {
          this.setState({
            dialogOpen: true, 
            errorMessage: response.errorMessage, 
            isUploading: false
          });
        } else {
          // Create verification promises for all files
          for (let i = 0; i < files.length; i += 1) {
            const { fileId, status } = files[i];
            if (status !== Constants.canceledStatus) {
              // Update status for each file
              const newStatus = response[fileId].status;
              files[i].status = newStatus;
              if (Utils.isNotNullorEmpty(response[fileId].errorMessage)) {
                const fileError = response[fileId].errorMessage;
                files[i].errorMessage = fileError;
              }
            }
          }  
          this.setState({ files });
          // Check to see if all statuses are processed, if not keep calling upload statuses
          const totalFileStatus = this.checkTotalStatus();
          if (totalFileStatus !== Constants.processedStatus) {
            return Utils.delay(1000).then(() => this.waitForVerifiedStatus(uploadId)); 
          }
        }
      });
  }

  // Gets presigned URLs for list of files
  postPresignedUrl = (tableName, datasetName, filesArr, done, uploadID, fileNum) => {
    const payload = {
      tableName,
      datasetName,
      files: filesArr,
      doneFile: done,
      uploadID,
      fileNum
    };

    return (
      DatasetService.postPresignedUrl(payload)
        .then((presignedResponse) => {
          if (presignedResponse.errorMessage != null) {
            this.setState({
              dialogOpen: true, 
              errorMessage: presignedResponse.errorMessage, 
              isUploading: false
            });
          } else {
            return presignedResponse;
          }
        }).catch((error) => {
          throw error;
        }) 
    );
  }
  
  // Handles a file change event by pulling file specific information
  handleFileChange = (event) => {
    const filesObj = event.target.files;
    const { tableSelected, datasetSelected } = this.state;
  
    const filesList = [];
    for (let i = 0; i < filesObj.length; i += 1) {
      const fileObj = filesObj[i];
      const fileName = fileObj.name;
      const fileSize = fileObj.size;
      let fileType = fileObj.type;
  
      this.setState({ dialogOpen: true, isUploading: true });
  
      // Pulls the file extension off of the file name
      const re = /(?:\.([^.]+))?$/;
      const fileExt = re.exec(fileName)[1];
  
      // Checks to make sure the file extension is supported by the app
      if (Constants.fileExtSupported.indexOf(fileExt) >= 0) {
        if (Constants.conversionFileExt.includes(fileExt)) {
          this.setState({ conversion: true });
          if (fileExt === 'xls') {
            fileType = 'application/vnd.ms-excel';
          }
        } 
      } else {
        const fileTypeError = ['File type uploaded invalid.', `Support file types include: ${Constants.fileExtSupported.join(', ')}`];
        this.setState({
          dialogOpen: true, errorMessage: fileTypeError, isUploading: false, files: []
        });
        return false;
      }
  
      filesList.push({
        fileName,
        contentType: fileType,
        fileSize
      });
    }

    const uploadID = uuidv4();
    const presignedPromises = [];
    const chunk = 100;
    if (filesList.length > chunk) {
      let fileSlice;
      for (let i = 0; i < filesList.length; i += chunk) {
        const fileNum = i;
        fileSlice = filesList.slice(i, i + chunk);
        // In the last chunk of files, set true for .done file to be created
        let doneFile = false;
        const remainingAmount = filesList.length - i;
        if (remainingAmount < chunk) {
          doneFile = true;
        }
        presignedPromises.push(this.postPresignedUrl(
          tableSelected, datasetSelected, fileSlice, doneFile, uploadID, fileNum
        ));
      }
    } else {
      presignedPromises.push(this.postPresignedUrl(
        tableSelected, datasetSelected, filesList, true, uploadID
      ));
    }
    
    // Build a list of upload promises to execute with all so wait for completion
    const uploadPromises = [];

    Promise.all(presignedPromises)
      .then((newPresignedResponse) => {
        let newFilesArray = [];
        newPresignedResponse.forEach((array) => { newFilesArray = newFilesArray.concat(array); });

        let doneFile;
        for (let i = 0; i < newFilesArray.length; i += 1) {
          if (!newFilesArray[i].doneFile) {
            newFilesArray[i].loadedBytes = 0;
            newFilesArray[i].totalBytes = 0;
            if (newFilesArray[i].errorMessage !== '' && newFilesArray[i].errorMessage !== undefined) {
              newFilesArray[i].status = Constants.uploadFailedStatus;
            }
          } else {
            doneFile = newFilesArray[i];
          }      
        }
        // Remove the .done file from the files list
        newFilesArray = newFilesArray.filter((obj) => obj.doneFile !== true);

        this.setState({
          dialogOpen: true, isUploading: true, files: newFilesArray
        });
        
        // Loop through the files in the response back and upload the files
        for (let i = 0; i < newFilesArray.length; i += 1) {
          // Only proceed if there weren't any issues with uploading the file
          if (newFilesArray[i].status !== Constants.uploadFailedStatus) {
            // Pulls the files object to upload
            for (let f = 0; f < newFilesArray.length; f += 1) {
              // Checks against the list of files objects to find the correct file 
              //  and ensures the upload has not failed
              if (newFilesArray[i].fileName === filesObj[f].name) {
                const { presignedURL, fileId } = newFilesArray[i];
                uploadPromises.push(this.uploadFile(filesObj[f], presignedURL, fileId, i));
                break;
              }
            }
          }       
        }

        // Wait for all files to be uploaded then create .done file and upload
        Promise.all(uploadPromises).then(() => {
          const blob = new Blob([], { type: '' });
          const doneFileObj = new File([blob], doneFile.fileName);
      
          // Check to see if files have been canceled and only send done file if they haven't been
          const totalFileStatus = this.checkTotalStatus();
          if (totalFileStatus !== Constants.canceledStatus) {
            DatasetService.uploadFile(doneFileObj, doneFile.presignedURL, doneFile.fileId, {})
              .then(() => {                  
                const { uploadId } = doneFile;
                this.waitForVerifiedStatus(uploadId);
              }).catch((error) => {
                this.setState({
                  dialogOpen: true, errorMessage: error.message, isUploading: false, files: []
                });
              }); 
          }    
        });
      }).catch((error) => {
        this.setState({
          dialogOpen: true, errorMessage: error.errorMessage, isUploading: false, files: []
        });
      });
   
    return null;
  }

  // Update state with dataset selected if it changed
  handleDatasetChange = (event, newValue) => {
    const { datasetSelected } = this.state;
    if (newValue) {
      if (newValue !== datasetSelected) {
        this.setState({ datasetSelected: newValue, tableSelected: '' });
      }
    }
  }

  // Pulls validation attribute and sets state based on table name
  getValidation = (tableName) => {
    const { datasetSelected } = this.state;
    const { dataset } = this.props;
    const { datasetObjs } = dataset;

    const datasetObj = datasetObjs.find((x) => x.datasetName === datasetSelected);
    if (datasetObj !== undefined) {
      const { uploadTables } = datasetObj;
      if (uploadTables) {
        const tableObj = uploadTables.find((x) => x.tableName === tableName);
        if (tableObj) {
          const { validation } = tableObj;
          this.setState({ validation });
        } 
      }
    }
  }
  
  // Update state with table selected if it changed
  // Add query parameters with dataset and table
  handleTableChange = (event, newValue) => {
    const { tableSelected, datasetSelected } = this.state;
    const { history } = this.props;

    if (newValue) {
      if (newValue !== tableSelected) {
        this.setState({
          tableSelected: newValue, historyLoaded: false
        });
  
        history.push({ pathname: '/upload', search: `?dataset=${datasetSelected}&table=${newValue}` });
  
        DatasetService.getUploadHistory(datasetSelected, newValue).then((response) => {
          if (response.errorMessage === '' || response.errorMessage === undefined) {
            const historyObjs = response.sort((a, b) => b.timestamp - a.timestamp);
            this.setState({ historyObjs, historyLoaded: true });
          } else {
            this.setState({ 
              dialogOpen: true, 
              errorMessage: response.errorMessage, 
              isUploading: false 
            });
          }      
        }).catch((error) => {
          this.setState({ dialogOpen: true, errorMessage: error.message, isUploading: false });
        });
      }
    }
  };
  
  // Create an autocomplete select box for either datasets or tables depending on param passed
  generateAutocomplete = (type) => {
    const { datasetSelected, tableSelected } = this.state;
    const { dataset } = this.props;
    const { datasetObjs } = dataset;

    let options = []; 
    let optionsText;
    let value;
    let disabled = false;

    if (type === 'Dataset') {
      // Build a list of upload datasets based on if they have upload tables
      const uploadDatasets = [];
      for (let i = 0; i < datasetObjs.length; i += 1) {
        const { datasetName } = datasetObjs[i];
        const { uploadTables } = datasetObjs[i];
        // Checks to see if there are upload tables, which means dataset is available for upload
        if (uploadTables.length) {
          uploadDatasets.push(datasetName);
        }
      }

      options = uploadDatasets;
      optionsText = 'No Datasets Found';
      value = datasetSelected;
    } else {
      const datasetObj = datasetObjs.find((x) => x.datasetName === datasetSelected);
      if (datasetObj !== undefined) {
        const { uploadTables } = datasetObj;
        if (uploadTables) {
          options = uploadTables.map((a) => a.tableName);
        }
      }
      optionsText = 'No Tables Found';
      value = tableSelected;
      disabled = this.disableTableSelect();
    }

    const autocomplete = (
      <Autocomplete
        id={`autocomplete-${type}`} 
        value={value}
        options={options}
        onChange={type === 'Dataset' ? this.handleDatasetChange : this.handleTableChange} 
        noOptionsText={optionsText}
        selectOnFocus
        closeIcon=""
        getOptionLabel={(option) => option}
        getOptionSelected={(option, optionValue) => {
          if (optionValue === '') {
            return true;
          } if (optionValue === option) {
            return true;
          }
        }}
        classes={{ inputRoot: 'menu-selected', root: 'menu-selected' }}
        style={{ minWidth: 280 }}
        disabled={disabled}
        renderInput={(params) => (
          <TextField 
            InputProps={{
              ...params.InputProps,
              inputProps: {
                ...params.inputProps
              }
            }} 
            label={type}
            InputLabelProps={{ classes: { root: 'menu-selected' } }}
            disabled={disabled}
          />
        )}
      />
    );

    return autocomplete;
  }

  handleInfoClick = () => {
    this.setState({ info: true, dialogOpen: true });
  }
    
  generateInfoTable = (statusArray) => {
    const infoTable = [
      <TableHead className="info-header">
        <TableRow>
          <TableCell className="info-header-center-20">Icon</TableCell>
          <TableCell className="info-header-20">Name</TableCell>
          <TableCell className="info-header-60">Description</TableCell>
        </TableRow>
      </TableHead>
    ];
    
    for (let i = 0; i < statusArray.length; i += 1) {
      let iconClassName = 'info-icon-row';
      if (statusArray[i].className) {
        iconClassName = statusArray[i].className;
      }
      const infoTableCell = (
        <TableRow className="info-icon-row">
          <TableCell className={iconClassName}>
            {statusArray[i].icon}
          </TableCell>
          <TableCell className="info-row">
            {statusArray[i].name}
          </TableCell>
          <TableCell className="info-row">
            {statusArray[i].description}
          </TableCell>
        </TableRow>
      );
      infoTable.push(infoTableCell);   
    }

    return infoTable;
  }

  generateUploadButton() {
    const { isUploading } = this.state;
    
    let button;
    if (isUploading) {
      button = (
        <Button variant="outlined" className="upload-button" disabled={this.isUploadDisabled()} startIcon={<CloudUploadIcon />}>
          Upload
        </Button>
      );
    } else {
      button = (
        <Tooltip disableFocusListener disableTouchListener title={this.isUploadDisabled() ? 'Please select a dataset and table before uploading' : ''}>
          <div>
            <Button onClick={(e) => this.upload.click()} variant="outlined" className="upload-button" disabled={this.isUploadDisabled()} startIcon={<CloudUploadIcon />}>
              Upload
            </Button>
          </div>
        </Tooltip>
      );
    } 
    return (
      <Grid key="upload-button" item xs={2} className="upload-button-div">
        <input id="fileInput" type="file" multiple accept={Constants.fileExt} ref={(ref) => { this.upload = ref; }} className="file-browser" onChange={this.handleFileChange} />
        {button}
      </Grid>
    );
  }

  // Checks status of all files to determine overall status
  checkTotalStatus() {
    const { files, conversion, validation } = this.state;
    const fileStatus = [];
    let totalFileStatus = Constants.processingStatus;

    if (files.length) {
      for (let i = 0; i < files.length; i += 1) {
        if (files[i].status) {
          fileStatus.push(files[i].status);
        }      
      }
      
      if (fileStatus.length === files.length) {
        if (fileStatus.every((elem) => elem === Constants.canceledStatus)) {
          totalFileStatus = Constants.canceledStatus; 
        } else if (
          // If conversion but no validation required, make sure file is converted/conversion failed
          (conversion && !validation && Utils.arrayContains(Constants.conversionStatuses, fileStatus)) 
          // If no conversion/validation required, considered complete when begin_processing status
          || (!conversion && !validation && Utils.arrayContains(Constants.noProcessingStatuses, fileStatus))
          // If validation is required, looking for validated status or error in the process
          || (validation && Utils.arrayContains(Constants.processedStatuses, fileStatus))
        ) {
          totalFileStatus = Constants.processedStatus; 
        } else if (fileStatus.every((elem) => elem !== Constants.uploadingStatus)) {
          totalFileStatus = Constants.uploadedStatus;
        }
      }     
    }

    return totalFileStatus;
  }
  
  generateDialog() {
    let dialogContent = (
      <DialogContent className="dialog-content">
        <div className="dialog-div-verify">
          <CircularProgress className="circular-progress" />
        </div>
      </DialogContent>   
    );
    let dialogActions;
    let dialogTitle;
    const {
      isUploading, errorMessage, dialogOpen, files, info
    } = this.state;

    let dialogClassName = 'dialog';

    if (info) {
      dialogClassName = 'info-dialog';
      dialogTitle = <DialogTitle id="info-dialog-title" className="dialog-title">Upload Info</DialogTitle>;
      dialogContent = (
        <DialogContent className="info-dialog-content">
          <Table className="dialog-table">
            {this.generateInfoTable(
              [
                {
                  icon: <ErrorIcon className="warning-icon" />,
                  name: 'Canceled',
                  description: 'File(s) canceled by the user and not processed.'
                },
                {
                  icon: <ErrorIcon className="error-icon" />,
                  name: 'Error',
                  description: 'File(s) encountered error, either in upload, validation, conversion or processing. Click icon for more details.'
                },
                {
                  icon: (
                    <div className="cloud-icon-div">     
                      <CloudIcon className="cloud-icon" />
                      <CircularProgress size={45} className="cloud-circular-progress" />  
                    </div>
                  ),
                  name: 'Verifying',
                  description: 'File(s) being validated and converted if required.',
                  className: 'info-smaller-cell'
                },
                {
                  icon: <CloudDoneIcon className="cloud-done-icon" />,
                  name: 'Verified',
                  description: 'File(s) successfully validated and converted.',
                  className: 'info-smaller-cell'
                },
                {
                  icon: (
                    <div className="cloud-icon-div">     
                      <CloudDoneIcon className="cloud-done-icon" />
                      <CircularProgress size={45} className="cloud-circular-progress" />  
                    </div>   
                  ),
                  name: 'Processing',
                  description: 'File(s) landed successfully in data lake. Jobs in process to load into data warehouse.',
                  className: 'info-smaller-cell'
                },
                {
                  icon: <CheckCircle className="success-icon" />,
                  name: 'Complete',
                  description: 'File(s) successfully loaded into data warehouse and data is now available in RAPTOR (consumable via web app and Snowflake).'
                },          
              ]
            )}
          </Table> 
        </DialogContent>
      );
      dialogActions = (
        <DialogActions>
          <Button onClick={this.handleDialogClose} className="dialog-button">
            OK
          </Button>
        </DialogActions>
      );
    } else if (isUploading) {
      dialogTitle = <DialogTitle id="alert-dialog-title" className="dialog-title">Upload Files</DialogTitle>;  
      const progressBars = [];      
      for (let i = 0; i < files.length; i += 1) {
        progressBars.push(
          <TableRow key={`file-progress-${i}`} className="dialog-row">
            <TableCell className="dialog-row-10">
              <DescriptionIcon className="file-icon" />
            </TableCell>
            <TableCell className="dialog-row-80">
              <div className="dialog-file-div">
                {files[i].fileName}
              </div>
              <div className="dialog-div">              
                <LinearProgress variant="determinate" value={this.calculateProgress(files[i].totalBytes, files[i].loadedBytes)} classes={{ colorPrimary: 'color', barColorPrimary: 'barcolor' }} />
              </div>
            </TableCell>
            <TableCell className="dialog-row-10">
              {this.getStatusIcon(files[i].status, files[i].fileId)}          
            </TableCell>
          </TableRow>
        );
        dialogTitle = <DialogTitle id="alert-dialog-title" className="dialog-title">Upload Files</DialogTitle>;
        if (progressBars.length !== 0) {
          dialogContent = (
            <DialogContent className="dialog-content">
              <Table className="dialog-table">
                {progressBars}
              </Table> 
            </DialogContent>
          );
        } 
        const totalFileStatus = this.checkTotalStatus();
          
        // Checks to see if file status is canceled or processed to show dialog button
        if (totalFileStatus !== Constants.processingStatus 
          && totalFileStatus !== Constants.uploadedStatus 
          && totalFileStatus !== Constants.uploadingStatus) {
          if (totalFileStatus === Constants.canceledStatus) {
            dialogTitle = <DialogTitle id="alert-dialog-title" className="dialog-title">Upload Canceled</DialogTitle>;
          }
          dialogActions = (
            <DialogActions>
              <Button onClick={this.handleDialogClose} className="dialog-button">
                OK
              </Button>
            </DialogActions>
          );
        }        
      }
    } else {
      if (errorMessage) {
        dialogTitle = <DialogTitle id="alert-dialog-title" className="dialog-title">An Error Occurred</DialogTitle>;
        const dialogText = [];
        if (Array.isArray(errorMessage)) {
          for (let i = 0; i < errorMessage.length; i += 1) {
            dialogText.push(
              <DialogContentText id="message" key={`error-message-${i}`}>
                {errorMessage[i]}
              </DialogContentText>
            );
          }
        } else {
          dialogText.push(
            <DialogContentText id="message" key="error-message">
              {errorMessage}
            </DialogContentText>
          );
        }
        dialogContent = (
          <DialogContent>
            <div className="error-icon-div">
              <ErrorIcon className="error-icon" />
            </div>
            {dialogText}
          </DialogContent>
        );
      }

      dialogActions = (
        <DialogActions>
          <Button onClick={this.handleDialogClose} className="dialog-button">
            OK
          </Button>
        </DialogActions>
      );
    }
    const dialog = (
      <Dialog
        key="dialog"
        open={dialogOpen}
        onClose={this.handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={dialogClassName}
      >
        {dialogTitle}
        {dialogContent}
        {dialogActions}
      </Dialog>
    );

    return dialog;
  }
  
  // Create history table section with table and refresh button
  generateHistorySection(historyLoaded) {
    const { historyObjs, tableSelected } = this.state;
    const historySection = [];
    if (historyLoaded) {
      const title = (
        <Grid container item xs={6} key="grid-history-header">
          <Typography variant="h5" className="recent-header">Recent Uploads</Typography>
        </Grid>
      );
      historySection.push(title);
      const refreshButton = (
        <Grid container item xs={6} className="refresh" justify="flex-end" key="grid-refresh">
          <div className="refresh-section">
            <Button className="refresh-button">
              <Tooltip title="Refresh">
                <SyncIcon onClick={this.handleRefresh} />
              </Tooltip>
            </Button>
            <Button className="info-button">
              <Tooltip title="Info">
                <InfoIcon onClick={this.handleInfoClick} />
              </Tooltip>
            </Button>
          </div>
        </Grid>
      );
      const historyTable = (
        <HistoryTable
          tableSelected={tableSelected}
          uploadHistory={historyObjs}
        />
      );
      historySection.push(refreshButton);
      historySection.push(historyTable);
    } else {
      const spin = (
        <Grid item xs={12} key="grid-cp-div">
          <div className="upload-cp-div">
            <CircularProgress className="circular-progress" />
          </div>
        </Grid>
      );
      historySection.push(spin);
    }

    return historySection;
  }

  // Disable table select box when dataset is not selected
  disableTableSelect() {
    const { datasetSelected, isUploading } = this.state;
    if (!datasetSelected && !isUploading) {
      return true;
    } return !!(datasetSelected && isUploading);
  }

  isUploadDisabled() {
    const { tableSelected } = this.state;
    return !tableSelected;
  }

  render() {
    const { dataset, auth } = this.props;
    const { tableSelected, historyLoaded, dialogOpen } = this.state;
    const { didLoad } = dataset;
    const { isAuthenticated } = auth;

    if (didLoad && isAuthenticated) {
      return (
        <Grid container className="upload">
          <Grid item xs={12}>
            <Typography variant="h4" className="uploadData-header">Upload Data</Typography>
          </Grid>
          <Grid item xs={12} className="select-boxes">
            <FormControl style={{ minWidth: 140, paddingRight: 70 }}>
              {this.generateAutocomplete('Dataset')}
            </FormControl>
            <FormControl style={{ minWidth: 140 }}>
              <Tooltip disableFocusListener disableTouchListener title={this.disableTableSelect() ? 'Please select a dataset' : ''}>
                {this.generateAutocomplete('Table')}
              </Tooltip>   
            </FormControl>
          </Grid>
          {this.generateUploadButton()}
          <Grid item xs={10} />
          <Grid container className="history">
            {tableSelected !== ''
              ? (
                this.generateHistorySection(historyLoaded)
              )
              : null}
          </Grid>
          {dialogOpen ? this.generateDialog() : null}
        </Grid>
      );
    } 
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h4" className="uploadData-header">Upload Data</Typography>
          <div className="upload-cp-div">
            <CircularProgress className="circular-progress" />
          </div>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  dataset: state.dataset,
  auth: state.auth
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

uploadData.propTypes = {
  dataset: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(uploadData));
