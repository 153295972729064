import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Constants from '../services/constants';
import './retool.scss';

function Retool() {
  return (
    <Grid container className="retool">
      <Grid item xs={12}>
        <Typography variant="h4" className="retool-header">Retool</Typography>
      </Grid>
      <Grid item xs={12} className="retool-iframe-cell">
        <iframe className="retool-iframe" src={`${Constants.retoolLinks[Constants.env]}`} width="100%" height="800px" />
      </Grid>
      <Grid item xs={12} className="retool-cell">
        <Typography align="right" fontSize="14px">
          <a href={`${Constants.retoolLinks[Constants.env]}`} target="_blank" rel="noreferrer noopener" className="retool-link">Explore in Retool</a>               
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Retool;